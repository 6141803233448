import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import get from "lodash/get";
import Layout from "../components/layout";

import { Row, Col } from "react-bootstrap";

import styles from "./static-page.module.css";

class StaticPageTemplate extends React.Component {
  render() {
    const staticPage = get(this.props, "data.contentfulStaticPage");

    return (
      <Layout location={this.props.location}>
        <div>
          <Helmet title={staticPage.title} />
          <div className="wrapper">
            <Row>
              <Col sm={12}>
                <h1 className={styles.sectionHeadline}>{staticPage.title}</h1>
                <div
                  dangerouslySetInnerHTML={{
                    __html: staticPage.content.childMarkdownRemark.html
                  }}
                />
              </Col>
            </Row>
          </div>
        </div>
      </Layout>
    );
  }
}

export default StaticPageTemplate;

export const pageQuery = graphql`
  query StaticPageBySlug($slug: String!) {
    contentfulStaticPage(slug: { eq: $slug }) {
      id
      title
      slug
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
